@import url(http://fonts.googleapis.com/css?family=Open+Sans:400italic,400,300,600,700);
@import 'reset';
@import 'general_styles';
@import 'bootstrap';
@import 'jquery-ui-smoothness';
@import 'sweetalert';
@import 'perfect-scrollbar';
@import 'cms-colors';

.show_loading_gif{
	// display:none;
	position: fixed;
	background-image: url("/../images/dark_noise_opacity.png");
	width:100%;
	height:100%;
	z-index:10000000;
	img{
		position: fixed;
		top:50%;
		margin-top:-10px;
		left:50%;
		margin-left:-110px;
	}
	.saving_progress{
		color: white;
		font-size: 20px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-left: -147px;
		margin-top: -50px;
	}
}
html{
    min-height:100%;/* make sure it is at least as tall as the viewport */
    position:relative;
}
a{
    &:hover{
        text-decoration: none!important;
    }
}
.application_messages{
	&:before{
		content:'PLEASE NOTE - ';
	}
	position: fixed;
	z-index: 1000000000000;
	bottom: 0px;
	padding: 20px;
	text-align: center;
	background: white;
	width: 100%;
}
.light{
  font-weight: 300;
}
.italic{
  font-style: italic;
  font-weight: 400;
}
.semi_bold{
  font-weight: 600;
}
.bold, th, strong{
  font-weight: 700!important;
  font-family:inherit!important;
}
.light_blue_bg{
    background:lighten($dark_blue, 10%);
}
.light_blue{
    //color:#0090ce;
    color:lighten($blue, 60%);
}
body.new_cms{
    font-family: 'Open Sans', sans-serif!important;
    background-color:$blue!important;
    height:100%;
    position:relative;
	font-weight:300;
	font-size:12px;
	*{
		font-size:12px;
	}
	h1{
		font-size:1.5em!important;
	}
	h2{
		font-size:1.4em!important;
	}
	h3{
		font-size:1.25em!important;
	}
	h4, label{
		font-size:1.08em!important;
	}
	.btn{
		border-radius:0px!important;
	}
    .top_cms_bar{
        position:fixed;
        top:0px;
        width:100%;
        z-index:10000;
        .cms_top_bar_left{
            background:black;
            text-align:center;
            padding:15px;
            border-bottom:3px solid white;
            border-bottom:3px solid $dark_blue;
        }
        .cms_top_bar_right{
            background:white;
            @extend .white_bg;
            padding:15px;
            border-bottom:3px solid $dark_blue;
        }
    }
	.cms_top_bar_left, .nav_collumn{

	}
    .nav_collumn{
        background-color:$dark_blue!important;
        position:fixed!important;
        z-index:100;
        overflow:hidden;
        top:50px;
        padding-top:15px;
        bottom:0px;
        display:block;
		.active_cms_section{
			// background:lighten($blue,35%);
		}
        .cms_nav_toggle_close{
            display:none;
        }
        *{
            color:white;
        }
        .new_cms_nav{
            .cms_nav_parent{
				cursor:pointer;
                .cms_section_heading{
                    padding:5px 15px;
                    @extend .semi_bold;

                    transition:all 0.2s ease;
                    cursor: pointer;
                    &:hover{
                        background:lighten($dark_blue, 10%);
                    }
                }
            }

            .active_module, .active_cms_page{
                background:lighten($dark_blue, 20%);
                border-left:5px solid lighten($dark_blue, 40%);
				ul > li{
					padding:0px 25px!important;
				}
            }
            a{
                text-decoration:none;
            }
            .cms_subnav{
                display:none;
                margin-bottom:5px;
                li{

                }

                .module_list_name{
                    padding:5px 25px;
                    font-weight:700;

                    &:hover{
                        background:lighten($blue, 10%);
                    }
                }
                ul{
                    display:none;
                    li{

                        padding:0px 15px;
                        margin-bottom:0px;
                        &:hover{
                            background:lighten($blue, 20%);
                        }
                        a{
                            &:before{
                                content: "\00a0 \00a0 \00a0 ";
                            }
                            height:100%;
                            display:block;
                            padding-top:5px;
                            padding-bottom:5px;
                        }
                    }
                }

            }
            .non_module_subnav{
                li{

                    padding:0px 15px;
                    margin-bottom:0px;
                    &:hover{
                        background:lighten($blue, 20%);
                    }
                    a{
                        &:before{
                            content: "\00a0 \00a0 \00a0 ";
                        }
                        height:100%;
                        display:block;
                        padding-top:5px;
                        padding-bottom:5px;
                    }
                }
            }
        }
        .ps-container > .ps-scrollbar-y-rail {
            display: none;
            position: absolute;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            border-radius: 4px;
            opacity: 1;
        }
    }
    .main_cms_content{
        // background:lighten($blue, 10%);
        float:right!important;
        padding:0px!important;
        margin-top:50px;
        p,li,a,label, h1,h2,h3,h4,h5{
            color:white;
        }
        .cms_content{
            padding-top:15px;
            padding-bottom:15px;
			.form-control, #cms_searchbar{
				border-radius: 0px!important;
			}
            h1:first-child{

                // background:red;
                // position:fixed;
                // width: 81.2% !important;
                // top:30px;
                // padding-bottom:10px;
            }
            #cms_searchbar, .form-control{
                color:$dark_blue;
                // position:fixed;
                // background:green;
                // top:130px;
                // width: 81.2% !important;
            }
            .btn-primary, .btn-success{
                color:white;
            }
            .glyphicon-pencil, .glyphicon-ok, .glyphicon-remove, .glyphicon-search, .glyphicon-plus{
                position:relative;
                top:3px;
                width:30px;
                height:30px;
                &:before{
                    content:'';
                }

            }
			.glyphicon-duplicate{
				position:relative;
                top: 9px;
                width:30px;
                height:33px;
				color:black;
			}
            .glyphicon-list-alt{
                width:27px;
                height:31px;
                &:before{
                    position: relative;
                    top: 7px;
                }
            }
            .glyphicon-pencil{
                background-image:url('../images/cms_icons/pencil.png');
            }
            .glyphicon-ok{
                background-image:url('../images/cms_icons/tick.png');
            }
            .glyphicon-remove{
                background-image:url('../images/cms_icons/cross.png');
            }
            .glyphicon-search{
                background-image:url('../images/cms_icons/search.png');
            }
			.glyphicon-plus{
                background-image:url('../images/cms_icons/plus.png');
            }
        }
    }
    .refresh_search_data{
        position:fixed;
        bottom:0px;
        right:0px;
        z-index:5;
    }
    .article_table_heading{
        background:$light_grey;
        margin-bottom:0px;
        border-top:1px solid $blue;
        border-bottom:1px solid white;
        *{
            color:lighten($blue, 20%)!important;
        }
    }
	.article_table, .article_table_heading{
		th, td{
			vertical-align: middle;
			word-break: break-all;
		}
		.btn{
			border:none;
			background:transparent!important;
		}
		.batch_delete_records{
			display:none;
			color:white!important;
			background:red!important;
			&:hover{
				background:darken(red, 5%)!important;
			}
		}
	}
	.filtered_nav_choices, .all_posts_list, .add_nav_item{
		display:none;
	}
    .navigation_selections{
    	display:none;
    }
    /*Visibility buttons*/
    .top_form_buttons{
        position:absolute;
        top:20px;
        right:20px;
        .top_button{
            float:left;
            text-align:center;
            h3{
				font-size:1em!important;
                position:relative;
                top:-15px;
            }
        }
        .visibility_button{
            margin-right:20px;
        }
        .preview_button{

        }
    }

    .mce-container{
        *{
            color:$dark_blue;
        }
    }
    .update_heading{
        margin-top:60px;
    }
    .cms_main_form{

    }
    /*Media Library/File Posts*/
    .file_instruction_list{
    	padding:5px;
    	color:$blue;
    	font-weight:700;
    	margin-bottom:20px;
    	li:first-child{

    	}
    }
    .show_loading_gif{
    	// display:none;
    	position: fixed;
    	//background-image: url("/../images/dark_noise_opacity.png");
    	width:100%;
    	height:100%;
    	z-index:10000000;
    	img{
    		position: fixed;
    		top:50%;
    		margin-top:-10px;
    		left:50%;
    		margin-left:-110px;
    	}
    	.saving_progress{
    		color: white;
    		font-size:20px;
    		position: fixed;
    		top: 50%;
    		left: 50%;
    		margin-left: -147px;
    		margin-top: -50px;
    	}
    }
    .newly_added_images{
    	  float: left;
    	  margin-bottom: 10px;
    	  margin-right: 10px;
    	  cursor: pointer;
    	  width: 227px;
    	  height: 227px;
    	  position: relative;
    	  overflow: auto;
    	  background: white;
    	  text-align:center;
    }
    .squared_cms_image{
        &:before{
            content: "";
            display: block;
            padding-top: 100%;
        }
        float:left;
        margin-bottom:1%;
        margin-right:1%;
        cursor:pointer;

        position:relative;
        overflow:hidden;
        background: #f3f3f3;
    }
    .media_library_image{
        @extend .squared_cms_image;
        width:12%;
    }
    .current_files_in_post{
    	ul{
    		padding: 10px 0px 0px 10px;
    		background:$cms_form_bg;
    		overflow: auto;
    	}
    	.delete_container{
    		background-image:url('/../images/warning_light_overlay.png');
    	}
    	li{
            @extend .squared_cms_image;
            width:19%;
            overflow: auto!important;
    	}
    	.delete_files_overlay{
    		position: absolute;
    		top:0px;
    		left:0px;
    		display:none;
    	}

    	.cms_file_meta_container{
    		position:absolute;
    		top:0px;
    		left:0px;
    		
    		background-image:url('/../images/dark_opacity.png');
    		width:100%;
    		min-height:100%;
    		padding:5px;
    		// display:none;
    		z-index:10000;
    		label{
    		}
    		hr{
    			margin-top:5px;
    			margin-bottom:5px;
    		}
    	}
    }
    .file_toolbar{
    	width:100%;
    	padding:5px;
        .toolbar_button{
            width:23%;
            padding: 20px 12px;
            margin-right:6px;
            border-radius:0px!important;
        }
        .toolbar_button:first-child{
            margin-left:5px;
        }
    }
	.confirm_deletions{
		display:none;
	}
    .file_type_document{
    	.file_information{
    		opacity:1!important;
    	}

    }
    .preview_the_file{
    	color:#449D44!important;
    }
    .btn{
    	transition: background ease 0.3s;
    }
    .cancel_delete_files{
    	transition: background ease 0.3s;
    	
    	width:150px;
    	text-align:center;
    	float:right;
    	margin-right:3px;
    	color: #fff;
      	background-color: #f0ad4e;
      	border-color: #eea236;
    	padding: 6px 12px;
    	margin-bottom: 0;
    	font-weight: 400;
    	line-height: 1.42857143;
    	text-align: center;
    	white-space: nowrap;
    	vertical-align: middle;
    	-ms-touch-action: manipulation;
    	touch-action: manipulation;
    	cursor: pointer;
    	-webkit-user-select: none;
    	-moz-user-select: none;
    	-ms-user-select: none;
    	user-select: none;
    	background-image: none;
    	border: 1px solid transparent;
    	border-radius: 4px;
    	&:hover{
    		background:#E29324;
    	  }
    }
    .add_files_to_post, .cancel_currently_selected_files{
    	
    	width:136px;
    	float:left;
    	margin-right:3px;
    }
    .main_media_library_container{
    	position:fixed;
    	top:0px;
    	padding:20px;
    	left:0px;
    	z-index:10000;
    	right:0px;
        bottom:0px;
    	background-image:url('/../images/dark_opacity.png');
    	display:none;
    	.the_media_library_holder{
    		background:$cms_form_bg;
    		padding:20px;
    		border-radius:4px;
    		// height:100%;
    		overflow:auto;
    		position: relative;
    		.selected_file_to_add{
    			background:green;
    			border:3px solid green;
    		}
    		.file_container{
    			&:hover{
    				border:3px solid green;
    			}
    		}
    	}

    }
    .file_instruction_list{
    	li{
        	margin-bottom: 8px;
            @extend .light_blue;
    	}
    }
    .overflow_options{
    	padding: 10px 5px 5px 0px;
    	background:#4c6172;
    	max-height:215px;
    	overflow:hidden;
    	position: relative;
    }

    .file_container{
    	background:#f3f3f3;
    	float:left;
    	.file_size_setter{
            visibility: hidden;
        }
    	margin-right:3px;
    	margin-bottom:3px;
    	text-align:center;
    	cursor:pointer;
    	border:3px solid white;
    	position: relative;
    	img{

    	}
    	.file_information{
    		position:absolute;
    		top:0px;
    		left:0px;
    		right:0px;
    		bottom:0px;
    		background-image:url('/../images/dark_noise_opacity.png');
    		z-index:10;
    		opacity:0;
    		padding:20px;
    		transition:opacity ease 0.3s;
    		&:hover{
    			opacity:0.8;
    		}
    		.document_name{
    			word-break: break-word;
    		}
    	}
    }
    .media_library_options{
    	background:$cms_form_bg;
    	padding:5px;
    	position: fixed;
    	bottom: 0px;
    	left: 0px;
    	width: 100%;
    	z-index: 100;
    	border-top:1px solid white;
    }
    #add_to_main_media_library{
    	width:150px!important;
    }
    /*Deleting files from posts*/
    .select_this_file{
    	background:#0c9710;
    	&:hover{
    		background:#0c9710;
    	}
    }
    .deletable_post_file{
    	border:3px solid red;
    }
    .delete_post_file_overlay{
    	position: absolute;
    	top:0px;
    	left:0px;
    	z-index:10;
    	width:100%!important;
    	height:100%!important;
    	// display:none;
    }
	.delete_post_file_overlay.can_delete_file{
    	display:block;
    }
    .in_post_delete{
    	display:block;
    }
    .batch_post_delete_active{
    	background-image: url("/../images/warning_light_overlay.png");
    }

    iframe.preview {
      -moz-transform: scale(0.8, 0.8);
      -webkit-transform: scale(0.8, 0.8);
      -o-transform: scale(0.8, 0.8);
      -ms-transform: scale(0.8, 0.8);
      transform: scale(0.8, 0.8);
      -moz-transform-origin: top left;
      -webkit-transform-origin: top left;
      -o-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      width:1200px;
      height:600px;
      margin-bottom:-100px;
      display:none;
    }
    .preview_the_page{
      cursor: pointer;
      margin:5px 0px;
    }
    .show_meta_container_images{
        color:$dark_blue;
    }
    .dz-message{
        span{
            @extend .light_blue;
            text-transform: uppercase;
        }

    }
    .form-horizontal .control-label, label{
    	color:#777;

        margin-bottom:8px;
    }
    .cms_form_checkbox{
    	label{
    	}
    }
    .small_dropzone{
        @extend .col-sm-4;
    }
    .current_files_in_post{
        margin-top:15px;
    }

    .cms_nav_toggle_show, .cms_nav_toggle_close{
        width:50px;
        height:50px;
        padding:0px;
        border:none;
        cursor: pointer;
        font-size:22px;

    }
    .cms_nav_toggle_show{

        position: fixed;
        z-index: 1000000;
        top:0px;
        display:none;
        left:0px;
        background:$dark_blue;
        &:hover{
            background:lighten($dark_blue, 10%);
        }

    }
    .cms_nav_toggle_close{
        background:lighten($dark_blue, 10%);
        margin-bottom: 20px;
        position: relative;
        margin-left: 100%;
        left: -39px;
        margin-top: -12px;
        &:hover{
            background:lighten($dark_blue, 20%);
        }
    }
    .nav_wrapper{
        width:100%;
        height:100%;
        position: relative;

    }

	.single_enquiry{
		background: rgba(0, 120, 255, .3);
		padding:10px;
		margin-bottom:20px;
	}
	.enquiry_response{
		display:none;
	}
	.toggleable_list_label{

		margin-left:2px;
	}
	.ui-sortable{
		cursor: move;
	}
}

@media (max-width:1600px){
    .current_files_in_post li{
        width: 24%!important;
    }
    .media_library_image{
        width:16%!important;
    }
}
@media (max-width:1200px){
    .current_files_in_post li{
        width: 32.2% !important;
    }
    .media_library_image{
        width:24%!important;
    }
}
@media (max-width:1000px){
    .current_files_in_post li{
        width:48.5% !important;
    }
}
@media (max-width:769px){
    .current_files_in_post li{
        width: 32.2% !important;
    }
    .media_library_image{
        width: 32.2%!important;
    }
    .nav_collumn{
        top:0px!important;
        width:250px;
        left:-250px;
        transition:all 0.2s ease;
    }
    .cms_nav_toggle_close{
        display:block!important;
    }
    .cms_nav_toggle_show{
        display:block!important;
    }
    .reponsive_nav_mode{
        left:0px;
    }

    .main_cms_content{
        float:left!important;
        width:100%!important;

    }
    .cms_form_link{

    }
    .cms_top_bar_left, .cms_top_bar_right {
        width:50%!important;
    }
    .cms_top_bar_right {
        text-align: center;
    }
    #search_map{
        margin-bottom:10px;
    }
}

@media (max-width:660px){
    .current_files_in_post li{
        width:48.5% !important;
    }
    .media_library_image{
        width:48.5% !important;
    }
}
@media (max-width:600px){
    .cms_top_bar_left, .cms_top_bar_right {
        width:100%!important;
    }
    .media_library_image{
        width:100%!important;
    }
    .cms_top_bar_right {
        display:none;
    }
    .top_form_buttons{
        position:relative!important;
        right:0px!important;
        top:0px;
        text-align: center;
        width:100%;
        .top_button {
            float: none!important;
            text-align: center;
            display: inline-block;

        }
    }
    .update_heading {
        margin-top:25px!important;
    }

}
@media (max-width:500px){
    .current_files_in_post li{
        width:100% !important;
        margin-bottom:10px!important;
    }
    .file_toolbar {
        .toolbar_button {
            width:100%!important;
            margin-right:0px!important;
            margin-bottom:10px;
            &:first-child{
                margin-left:0px!important;
            }
        }
    }
}
