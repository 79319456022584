/*Generic Formatting*/
a, a:link, a:active, a:visited, a:hover {
  text-decoration: none;
}
.clear {
  clear: both!important;
}
.float_left {
  float:left!important;
}
.float_right {
  float:right!important;
}
.uppercase{
  text-transform: uppercase;
}
.text_center{
  text-align:center!important;
  div{
    text-align:center!important;
  }
}
.text_left{
  text-align: left!important;
}
.text_right{
  text-align: right!important;
}
.display_block{
  display:block!important;
}
.inline_block{
  display:inline-block!important;
}
.flexbox_valign_center{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.no_side_margin{
  margin-left:0px;
  margin-right:0px;
}
.no_right_margin{
  margin-right:0!important;
}
.no_bottom_margin{
  margin-bottom:0!important;
}
.margin_auto{
  margin:auto;
}
.margin_top_5{
  margin-top:5px!important;
}
.margin_bottom_5{
  margin-bottom:5px!important;
}
.margin_top_10{
  margin-top:10px!important;
}
.margin_top_15{
  margin-top:15px!important;
}
.margin_bottom_10{
  margin-bottom:10px!important;
}
.margin_top_20{
  margin-top:20px!important;
}
.margin_bottom_20{
  margin-bottom:20px!important;
}
.margin_top_30{
  margin-top:30px!important;
}
.margin_bottom_30{
  margin-bottom:30px!important;
}
.margin_top_40{
  margin-top:40px!important;
}
.margin_bottom_40{
  margin-bottom:40px!important;
}
.margin_top_50{
  margin-top:50px!important;
}
.margin_bottom_50{
  margin-bottom:50px!important;
}
.margin_top_60{
  margin-top:60px!important;
}
.margin_bottom_60{
  margin-bottom:60px!important;
}
.margin_top_70{
  margin-top:70px!important;
}
.margin_bottom_70{
  margin-bottom:70px!important;
}
.margin_top_80{
  margin-top:80px!important;
}
.margin_bottom_80{
  margin-bottom:80px!important;
}
.margin_top_100{
  margin-top:100px!important;
}
.margin_bottom_100{
  margin-bottom:100px!important;
}
.margin_right_5{
  margin-right: 5px!important;
}
.margin_right_10{
  margin-right: 10px!important;
}
.margin_right_15{
  margin-right: 15px!important;
}
.margin_right_20{
  margin-right: 20px!important;
}
.margin_right_25{
  margin-right: 25px!important;
}
.margin_right_30{
  margin-right: 30px!important;
}
.relative{
	position: relative!important;
}
.position_top_back_30{
	position:relative;
	top:-30px;
}
.position_top_30{
	position:relative;
	top:30px;
}
.no_side_padding{
  padding-left:0px;
  padding-right:0px;
}
.padding_5{
  padding:5px;
}
.padding_10{
  padding:10px;
}
.padding_15{
  padding:15px;
}
.padding_20{
  padding:20px;
}
.padding_right_20{
    padding-right:20px!important;
}
.side_padding_20{
  padding-left:20px!important;
  padding-right:20px!important;
}
.padding_25{
  padding:25px;
}
.padding_50{
  padding:50px;
}
.padding_60{
  padding:60px;
}
.padding_80{
  padding:80px;
}
.padding_100{
  padding:100px;
}
.padding_top_20{
  padding-top:20px!important;
}
.padding_left_20{
  padding-left:20px!important;
}
.padding_bottom_20{
  padding-bottom:20px!important;
}
.padding_top_30{
	padding-top:30px;
}
.padding_bottom_30{
	padding-bottom:30px;
}
.padding_bottom_50{
  padding-bottom:50px;
}
.padding_top_60{
	padding-top:60px;
}
.padding_bottom_80{
  padding-bottom:80px;
}
.padding_top_80{
  padding-top:80px;
}
.padding_top_90{
  padding-top:90px;
}
.padding_top_100{
  padding-top:100px;
}
.padding_top_bottom_45{
  padding:45px 0px;
}
.white{
  color:white!important;
}
.white_opaque_bg{
 // background-image:url('../images/white_fifty_opacity.png')!important;
}
.white_bg{
  background:white;
}
.white_text, .white_text li, .white_text p, .white_text a, .white_text h1, .white_text h2, .white_text h3, .white_text h4, .white_text div, .white_text span{
  color:white!important;
}
.padding_bottom_60{
	padding-bottom:60px;
}
.no_bottom_margin{
	margin-bottom: 0px;
}
.hide{
	display:none;
}
.centered_image{
  display:block;
  margin:0 auto;
  max-width:100%;
}
.full_width_image{
	width:100%;
}
.max_full_width_image{
    max-width:100%;
}
.image_centered_in_div{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	margin: auto;
}
.absolutely_centered{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.vertically_centered{
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}
.bottom_centered{
  position:absolute;
  bottom:0px;
  left:50%;
  transform:translate(-50%, 0);
}
.absolute_centered_text{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.overflow_auto{
	overflow:auto;
}
.overflow_hidden{
  overflow:hidden;
}
.full_height{
  height:100%;
}
.absolute_centered_full_width{
  position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 200;
    width:100%;
    max-width: 1200px; /*Override for situation*/
}
/*Universal front and back end*/
.validation_error{
  background: #b5192f !important;
  font-size: 14px !important;
  color: white!important;
  padding: 0px 10px;
  line-height: 22px!important;
  margin-bottom: 3px;
}

/*Lists*/
.bulleted_list{
  list-style-type: disc;
  margin-left:20px;
}

.transparent_background{
  background-color: transparent!important;
}

/*Front end wysiwyg - can be overriden if need be*/
/*Wysiwyg*/
.wysiwyg_12px{
  font-size:12px!important;
  line-height: 18px;
  margin-bottom:20px;
}

.wysiwyg_14px{
  font-size:14px!important;
  line-height:22px;
  margin-bottom:20px;
}

.wysiwyg_16px{
  font-size:16px!important;
  line-height:24px;
  margin-bottom:20px;
}

.wysiwyg_18px{
  font-size:18px!important;
  line-height:28px;
  margin-bottom:20px;
}

.wysiwyg_20px{
  font-size:20px!important;
  line-height:30px;
  margin-bottom:20px;
}

.wysiwyg_22px{
  font-size:22px!important;
  line-height:32px;
  margin-bottom:20px;
}

.wysiwyg_24px{
  font-size:24px!important;
  line-height:34px;
  margin-bottom:20px;
}

.wysiwyg_26px{
  font-size:26px!important;
  line-height:36px;
  margin-bottom:20px;
}

.wysiwyg_28px{
  font-size:28px!important;
  line-height:38px;
  margin-bottom:20px;
}

.wysiwyg_30px{
  font-size:30px!important;
  line-height:40px;
  margin-bottom:20px;
}

.wysiwyg_32px{
  font-size:32px!important;
  line-height:42px;
  margin-bottom:20px;
}

.wysiwyg_36px{
  font-size:36px!important;
  line-height:48px;
  margin-bottom:20px;
}

.wysiwyg_40px{
  font-size:40px!important;
  line-height:52px;
  margin-bottom:20px;
}

.wysiwyg_48px{
  font-size:48px!important;
  line-height:60px;
  margin-bottom:20px;
}

.wysiwyg_54px{
  font-size:54px!important;
  line-height:66px;
  margin-bottom:20px;
}

.wysiwyg_60px{
  font-size:60px!important;
  line-height:72px;
  margin-bottom:20px;
}

.wysiwyg_80px{
  font-size:80px!important;
  line-height:80px;
  margin-bottom:20px;
}
.no_bottom_para_margin{
    p:last-child{
        margin-bottom:0px!important;
    }
}
.no_bottom_border{
    border-bottom:none!important;
}
.rotate_90{

  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  writing-mode: lr-tb;

}
.subtle_bottom_shadow{
    -webkit-box-shadow: 0px 3px 11px 0px rgba(50, 50, 50, 0.20);
    -moz-box-shadow:    0px 3px 11px 0px rgba(50, 50, 50, 0.20);
    box-shadow:         0px 3px 11px 0px rgba(50, 50, 50, 0.20);
}
.subtle_top_shadow{
    -webkit-box-shadow: 11px 3px 0px 0px rgba(50, 50, 50, 0.20);
    -moz-box-shadow:    11px 3px 0px 0px rgba(50, 50, 50, 0.20);
    box-shadow:         11px 3px 0px 0px rgba(50, 50, 50, 0.20);
}
/*Sliders*/
.plain_bx_slider{
    .bx-wrapper {
        position: relative;
        padding: 0;
        box-shadow: 0 0 0px #fff!important;
        border:none;
        background: #fff;
    }
}

.no_shadow_slider{
    .bx-wrapper {
        position: relative;
        padding: 0;
        box-shadow: 0 0 0px #fff!important;
        border:none;
        background: #fff;
    }
}
/*Typography*/
.prox_thin{
  font-family: 'ProximaThin';
  p,li,a,div,span{
     font-family: 'ProximaThin';
  }
}
.prox_light{
  font-family: 'proxima_novalight';
  p,li,a,div,span{
     font-family: 'proxima_novalight';
  }
}
.prox_semi{
  font-family: 'ProximaSemi';
}
.prox_bold{
  font-family: 'ProximaBold'!important;
}
.prox_italic{
  font-family: 'proxima_novaregular_italic';
}
.prox_light_italic{
  font-family: 'proxima_novalight_italic';
}
.prox_semibold_italic{
  font-family: 'proxima_novasemibold_italic';
}
.shadow_text{
	text-shadow: -2px 2px 2px rgba(150, 150, 150, 0.61);
}

/*Buttons and boxes*/
.auto_width_block{
    display:block;
    float:left;
    clear:both;
}
/*Mixins*/
@mixin padding_top_bottom($amount){
    padding-top:$amount;
    padding-bottom:$amount;
}

@mixin margin_top_bottom($amount){
    margin-top:$amount;
    margin-bottom:$amount;
}
@mixin padding_top_bottom($amount){
    padding-top:$amount;
    padding-bottom:$amount;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin top_bottom_borders($size, $colour) {
    border-top:$size solid $colour;
    border-bottom:$size solid $colour;
}

@mixin collapse_nav_early(){
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }

    .navbar-collapse.collapse {
        display: none!important;
    }

    .navbar-nav>li {
        float: none;
    }


    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
        display: block!important;
    }
}
